import { Draft } from '@reduxjs/toolkit';
import { createAppSlice } from '../..';
import { alerting } from '../../snacks';
import {
  Observation,
  ObservationBinary,
  ObservationBinaryId,
  ObservationId,
  addFilesToObservation,
  addedObservation,
  deleteFilesFromObservation,
  deleteObservation,
  updatedObservation,
} from '../general';

type BaseAsset = {
  id: string | number;
};

type AssetObservationsState<Asset extends BaseAsset> = {
  [key in Asset['id']]?: {
    isFetching: boolean;
    list: Observation[];
  };
};

function getInitialState<Asset extends BaseAsset>() {
  return {} as AssetObservationsState<Asset>;
}

function getInitialAssetState() {
  return { isFetching: false, list: [] };
}

export function createAssetObservationsSlice<Asset extends BaseAsset>(
  name: string,
  calls: { fetchObservations: (assetId: Asset['id'], fromDate?: string, toDate?: string) => Promise<unknown> },
) {
  type State = AssetObservationsState<Asset>;

  function initAsset(state: Draft<State>, assetId: Asset['id']) {
    (state as State)[assetId] ??= getInitialAssetState();
  }

  return createAppSlice({
    name,
    initialState: getInitialState<Asset>(),
    reducers: (create) => ({
      fetchAssetObservations: create.asyncThunk(
        async (payload: { assetId: Asset['id']; fromDate?: string; toDate?: string }, { dispatch }) => {
          const observations = (await alerting(
            () => calls.fetchObservations(payload.assetId, payload.fromDate, payload.toDate),
            { dispatch },
          )) as Observation[];
          return observations;
        },
        {
          pending: (state, { meta: { arg } }) => {
            initAsset(state, arg.assetId);
            (state as State)[arg.assetId]!.isFetching = true;
          },
          fulfilled: (state, { payload, meta: { arg } }) => {
            (state as State)[arg.assetId]!.list = payload;
          },
          settled: (state, { meta: { arg } }) => {
            (state as State)[arg.assetId]!.isFetching = false;
          },
        },
      ),
    }),
    extraReducers: (builder) => {
      function initReducer(
        draftState: Draft<State>,
        payload: {
          assetId?: Asset['id'];
          observationId?: ObservationId;
          observation?: Observation;
          newFiles?: ObservationBinary[];
          deletedFileIds?: ObservationBinaryId[];
        },
      ) {
        const { assetId, observation, observationId, deletedFileIds, newFiles } = payload || {};
        if (assetId) initAsset(draftState, assetId);
        const state = draftState as State;

        return { assetId, observation, observationId, deletedFileIds, newFiles, state };
      }

      builder.addCase(deleteObservation.fulfilled, (draftState, { payload }) => {
        const { assetId, observation, state } = initReducer(draftState, payload);
        if (assetId) {
          state[assetId]!.list = state[assetId]!.list.filter((o) => o.id !== observation?.id);
        }
      });

      builder.addCase(addedObservation, (draftState, { payload }) => {
        const { assetId, observation, state } = initReducer(draftState, payload);
        if (assetId) {
          state[assetId]!.list = [observation].filter(Boolean).concat(state[assetId]!.list || []);
        }
      });

      builder.addCase(updatedObservation, (draftState, { payload }) => {
        const { assetId, observation, state } = initReducer(draftState, payload);
        if (assetId) {
          state[assetId]!.list = state[assetId]!.list.map((o) => (o.id === observation?.id ? observation : o));
        }
      });

      builder.addCase(deleteFilesFromObservation.fulfilled, (draftState, { payload }) => {
        const { deletedFileIds, assetId, observationId, state } = initReducer(draftState, {
          ...payload,
          observationId: payload.observation.id,
          observation: undefined,
        });
        if (assetId) {
          state[assetId]!.list = state[assetId]!.list.map((o) =>
            o.id === observationId
              ? {
                  ...o,
                  binaries: ((Array.isArray(o.binaries) && o.binaries) || []).filter(
                    (b) => !deletedFileIds?.includes(b.id),
                  ),
                  updated: true,
                }
              : o,
          );
        }
      });

      builder.addCase(addFilesToObservation.fulfilled, (draftState, { payload }) => {
        const { newFiles, assetId, observationId, state } = initReducer(draftState, payload);
        if (assetId) {
          state[assetId]!.list = state[assetId]!.list.map((o) =>
            o.id === observationId
              ? {
                  ...o,
                  binaries: [...((Array.isArray(o.binaries) ? o.binaries : []) || []), ...(newFiles || [])],
                }
              : o,
          );
        }
      });
    },
  });
}
