import { Values } from '../utils/types';

export const OBSERVATION_CONSTANTS = {
  TYPE: {
    EQUIPMENT: 'equipment_instance',
    PARCEL: 'parcel',
    SESSION: 'equipment_session',
  },
  STATUS: {
    AVAILABLE: 'AVAILABLE',
    ARCHIVED: 'ARCHIVED',
  },
} as const;

export type ObservationStatus = Values<typeof OBSERVATION_CONSTANTS.STATUS>;
