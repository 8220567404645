import { getEquipmentObservations } from '../../api/api';
import { Equipment } from '../equipments';
import { createAssetObservationsSlice } from './abstract/asset';

const equipmentObservationsSlice = createAssetObservationsSlice<Equipment>('equipments', {
  fetchObservations: getEquipmentObservations,
});

const { fetchAssetObservations } = equipmentObservationsSlice.actions;
export { fetchAssetObservations as fetchEquipmentObservations };

export default equipmentObservationsSlice.reducer;
