import uniq from 'lodash-es/uniq';
import { Actions } from '../actions';

const initialState = {
  beaconsIDs: [],
  beaconsById: {},
  beaconEquipmentHistoryByID: {},
  beaconDriverHistoryByID: {},
  isDataFetch: false,
};

const beacons = (state = initialState, action) => {
  const { type, payload } = action;
  const { id, history } = payload || {};

  switch (type) {
    case Actions.BEACON_SUCCESS:
    case Actions.BEACONS_SUCCESS:
      return {
        ...state,
        beaconsIDs: uniq(state.beaconsIDs.concat(payload.result)),
        beaconsById: { ...state.beaconsById, ...payload.entities.beacon },
        isDataFetch: true,
      };
    case Actions.BEACON_SUCCESSFULLY_LINKED: {
      const { beaconID, equipmentID } = payload;
      return {
        ...state,
        beaconsById: {
          ...state.beaconsById,
          [beaconID]: {
            ...state.beaconsById[beaconID],
            currentEquipmentInstanceId: equipmentID,
          },
        },
        beaconEquipmentHistoryByID: {
          ...state.beaconEquipmentHistoryByID,
          [beaconID]: history,
        },
      };
    }
    case Actions.BEACON_SUCCESSFULLY_LINKED_TO_DRIVER:
    case Actions.DRIVER_AND_BEACON_LINKED: {
      const { driverID } = payload;
      return {
        ...state,
        beaconsById: {
          ...state.beaconsById,
          [id]: {
            ...state.beaconsById[id],
            currentUserId: driverID,
          },
        },
        beaconDriverHistoryByID: {
          ...state.beaconDriverHistoryByID,
          [id]: history,
        },
      };
    }
    case Actions.BEACON_DRIVER_SUCCESSFULLY_UNLINKED:
      return {
        ...state,
        beaconsById: {
          ...state.beaconsById,
          [id]: {
            ...state.beaconsById[id],
            currentEquipmentInstanceId: null,
            currentUserId: null,
          },
        },
        beaconDriverHistoryByID: {
          ...state.beaconDriverHistoryByID,
          [id]: history,
        },
      };
    case Actions.BEACON_EQUIPMENT_HISTORY_SUCCESS:
      return {
        ...state,
        beaconEquipmentHistoryByID: {
          ...state.beaconEquipmentHistoryByID,
          [id]: history,
        },
      };
    case Actions.BEACON_DRIVER_HISTORY_SUCCESS:
      return {
        ...state,
        beaconDriverHistoryByID: {
          ...state.beaconDriverHistoryByID,
          [id]: history,
        },
      };
    case Actions.BEACON_DRIVER_REMOVE_DRIVER:
      return {
        ...state,
        beaconsById: {
          ...state.beaconsById,
          [id]: {
            ...state.beaconsById[id],
            currentUserId: undefined,
          },
        },
      };
    case Actions.BEACON_LINK_EXISTING_TAG: {
      const beacon = state.beaconsById[payload.beaconId];
      return {
        ...state,
        beaconsById: {
          ...state.beaconsById,
          [payload.beaconId]: { ...beacon, tags: [...(beacon.tags || []), payload.tag] },
        },
      };
    }
    case Actions.BEACON_UNLINK_TAG: {
      const beacon = state.beaconsById[payload.beaconId];
      return {
        ...state,
        beaconsById: {
          ...state.beaconsById,
          [payload.beaconId]: {
            ...beacon,
            tags: (beacon.tags || []).filter((tag) => tag.id !== payload.tagId),
          },
        },
      };
    }
    case Actions.LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default beacons;
