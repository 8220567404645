import { getEquipmentSessionObservations } from '../../api/api';
import { EquipmentSessionId } from '../sessions';
import { createAssetObservationsSlice } from './abstract/asset';

const equipmentSessionObservationsSlice = createAssetObservationsSlice</* TODO: use equipment session type */ {
  id: EquipmentSessionId;
}>('equipmentsSessions', {
  fetchObservations: getEquipmentSessionObservations,
});

const { fetchAssetObservations } = equipmentSessionObservationsSlice.actions;
export { fetchAssetObservations as fetchEquipmentSessionObservations };

export default equipmentSessionObservationsSlice.reducer;
