import { getParcelObservations } from '../../api/api';
import { Parcel } from '../parcels';
import { createAssetObservationsSlice } from './abstract/asset';

const parcelObservationsSlice = createAssetObservationsSlice<Parcel>('parcels', {
  fetchObservations: getParcelObservations,
});

const { fetchAssetObservations } = parcelObservationsSlice.actions;
export { fetchAssetObservations as fetchParcelObservations };

export default parcelObservationsSlice.reducer;
