import { createBrowserHistory } from 'history';
import { createReduxHistoryContext } from 'redux-first-history';
import { reducer as form } from 'redux-form';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { loadState } from '../api/localStorage';
import articles from '../slices/articles';
import clusters from '../slices/clusters';
import colors from '../slices/colors';
import crops from '../slices/crops';
import equipmentSessionObservations from '../slices/observations/equipmentSessions';
import equipmentObservations from '../slices/observations/equipments';
import generalObservations from '../slices/observations/general';
import parcelObservations from '../slices/observations/parcels';
import phyto from '../slices/phyto';
import snacks from '../slices/snacks';
import tags from '../slices/tags';
import taskTypes from '../slices/taskTypes';
import tasks from '../slices/tasks';
import unitLocations from '../slices/unitLocations';
import beacons from './beacons';
import devices from './devices';
import equipments from './equipments';
import filters from './filters';
import parcels from './parcels';
import partner from './partner';
import reports from './reports';
import sessions from './sessions';
import ui from './ui';
import user from './user';
import worksites from './worksites';

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
  history: createBrowserHistory(),
});

const store = configureStore({
  reducer: {
    articles,
    beacons,
    colors,
    clusters,
    crops,
    devices,
    equipments,
    filters,
    form,
    observations: combineReducers({
      general: generalObservations,
      equipments: equipmentObservations,
      parcels: parcelObservations,
      equipmentsSessions: equipmentSessionObservations,
    }),
    parcels,
    partner,
    phyto,
    reports,
    router: routerReducer,
    sessions,
    snacks,
    tags,
    tasks,
    taskTypes,
    ui,
    unitLocations,
    user,
    worksites,
  },
  preloadedState: loadState(),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }).concat(routerMiddleware),
});

export default store;
export const history = createReduxHistory(store);
