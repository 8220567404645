import { createAppSlice } from '..';
import { Actions } from '../../actions';
import {
  addObservation,
  archiveObservation as archiveApiObservation,
  archiveObservations as archiveApiObservations,
  createObservationCategory as createApiObservationCategory,
  deleteObservation as deleteApiObservation,
  deleteObservationCategory as deleteApiObservationCategory,
  deleteObservations as deleteApiObservations,
  getObservationCategories,
  getObservations,
  postFilesToObservation,
  removeBinary,
  restoreObservation as restoreApiObservation,
  restoreObservations as restoreApiObservations,
  updateObservation as updateApiObservation,
  updateObservationCategory as updateApiObservationCategory,
} from '../../api/api';
import { OBSERVATION_CONSTANTS, ObservationStatus } from '../../constants/observationsConstants';
import { ItemWithPermissions } from '../../utils/permissions';
import { PartialWithRequired } from '../../utils/types';
import { ClusterId } from '../clusters';
import { Equipment, EquipmentId } from '../equipments';
import { Parcel, ParcelId } from '../parcels';
import { EquipmentSessionId } from '../sessions';
import { alerting, success } from '../snacks';
import { UserId } from '../users';

export type ObservationCategoryId = number;
export type BasicObservationCategory = ItemWithPermissions & {
  id: ObservationCategoryId;
  label: string;
  color?: string;
  obs_count?: number;
  cluster_id?: never;
  observation_category_id?: never;
};
export type CustomObservationCategory = ItemWithPermissions & {
  id: ObservationCategoryId;
  label: string;
  color?: string;
  obs_count?: number;
  cluster_id: ClusterId;
  observation_category_id: ObservationCategoryId;
};
export type ObservationCategory = BasicObservationCategory | CustomObservationCategory;

export type ObservationBinaryId = string;
export type ObservationBinary = {
  id: ObservationBinaryId;
  title: string;
};

export type ObservationId = string;
export type Observation = ItemWithPermissions & {
  id: ObservationId;
  content: string;
  status: ObservationStatus;
  equipment_instance_id?: EquipmentId;
  equipment_instance?: Equipment;
  author_id: UserId;
  firstname: string;
  lastname: string;
  updater_firstname?: string;
  updater_lastname?: string;
  from_application: string;
  occurred_at: string;
  added_at: string;
  updated?: boolean;
  updated_at: string;
  last_updater_id: UserId;
  /* TODO: use device type when available */
  device_instance_id?: number;
  device_instance?: unknown;
  device_session_id?: string;
  equipment_session_id?: EquipmentSessionId;
  /* TODO: use equipment session type when available */
  equipment_session?: unknown;
  location?: string;
  location_latitude?: number;
  location_longitude?: number;
  parcel_id?: ParcelId;
  parcel?: Parcel;
  pending?: boolean;
  binaries?:
    | ObservationBinary[]
    | {
        pending?: number;
      };
  observation_category_id?: ObservationCategoryId;
};

export type ObservationAssetId = ParcelId | EquipmentSessionId | EquipmentId;

type State = {
  categories: ObservationCategory[];
  list: Observation[];
  focusedObservationId: ObservationId | undefined;
};

const initialState: State = {
  categories: [],
  list: [],
  focusedObservationId: undefined,
};

const generalSlice = createAppSlice({
  name: 'general',
  initialState,
  reducers: (create) => ({
    // Observation categories
    fetchObservationCategories: create.asyncThunk(
      async (_, { dispatch }) => {
        const categories = (await alerting(getObservationCategories, { dispatch })) as ObservationCategory[];
        return categories;
      },
      {
        fulfilled: (state, { payload }) => {
          state.categories = payload;
        },
      },
    ),
    createObservationCategory: create.asyncThunk(
      async (category: Partial<ObservationCategory>, { dispatch }) => {
        const newCategory = (await alerting(() => createApiObservationCategory(category), {
          dispatch,
        })) as ObservationCategory;
        success('Observations.add_category_snack', { dispatch });
        return newCategory;
      },
      {
        fulfilled: (state, { payload }) => {
          state.categories.push(payload);
        },
      },
    ),
    updateObservationCategory: create.asyncThunk(
      async (category: PartialWithRequired<ObservationCategory, 'id'>, { dispatch }) => {
        const updatedCategory = (await alerting(() => updateApiObservationCategory(category.id, category), {
          dispatch,
        })) as ObservationCategory;
        success('Observations.update_category_snack', { dispatch });
        return updatedCategory;
      },
      {
        fulfilled: (state, { payload }) => {
          state.categories = state.categories.map((c) => (c.id === payload.id ? payload : c));
        },
      },
    ),
    deleteObservationCategory: create.asyncThunk(
      async (categoryId: ObservationCategoryId, { dispatch }) => {
        await alerting(() => deleteApiObservationCategory(categoryId), { dispatch });
        success('Observations.delete_category_snack', { dispatch });
        return categoryId;
      },
      {
        fulfilled: (state, { payload }) => {
          state.categories = state.categories.filter((c) => c.id !== payload);
        },
      },
    ),

    // Observations
    fetchObservations: create.asyncThunk(
      async (params: `?${string}`, { dispatch }) => {
        const observations = (await alerting(() => getObservations(params), { dispatch })) as Observation[] | undefined;
        return observations;
      },
      {
        fulfilled: (state, { payload }) => {
          state.list = (payload || []).toReversed();
        },
      },
    ),
    createObservation: create.asyncThunk(
      async (
        payload: {
          params: Partial<Observation> & { files?: File[] };
          assetId: ObservationAssetId;
        },
        { dispatch },
      ) => {
        const observation = (await alerting(() => addObservation(payload.params), { dispatch })) as Observation;
        if (payload.params.files?.length) {
          dispatch(
            addedObservation({
              observation: { ...observation, binaries: { pending: payload.params.files.length } },
              assetId: payload.assetId,
            }),
          );
          await dispatch(
            addFilesToObservation({
              observationId: observation.id,
              assetId: payload.assetId,
              files: payload.params.files,
            }),
          );
        } else {
          dispatch(
            addedObservation({
              observation,
              assetId: payload.assetId,
            }),
          );
        }
        success('Observations.add_observation_snack', { dispatch });
      },
    ),
    updateObservation: create.asyncThunk(
      async (
        payload: {
          observation: PartialWithRequired<Observation, 'id'> & {
            newFiles?: File[];
            deletedFiles?: ObservationBinary[];
          };
          assetId: ObservationAssetId;
        },
        { dispatch },
      ) => {
        const removeGeoloc = !payload.observation.location_longitude && !payload.observation.location_latitude;
        const newObservation = (await alerting(
          () =>
            updateApiObservation(payload.observation.id, {
              ...payload.observation,
              location_latitude: removeGeoloc ? null : payload.observation.location_latitude,
              location_longitude: removeGeoloc ? null : payload.observation.location_longitude,
            }),
          { dispatch },
        )) as Observation;
        dispatch(
          updatedObservation({
            observation: newObservation,
            assetId: payload.assetId,
          }),
        );
        if (payload.observation.deletedFiles?.length) {
          await dispatch(
            deleteFilesFromObservation({
              observationId: newObservation.id,
              assetId: payload.assetId,
              deletedFiles: payload.observation.deletedFiles,
            }),
          );
        }
        if (payload.observation.newFiles?.length) {
          await dispatch(
            addFilesToObservation({
              observationId: newObservation.id,
              assetId: payload.assetId,
              files: payload.observation.newFiles,
            }),
          );
        }
        success('Observations.update_observation_snack', { dispatch });
      },
    ),
    addFilesToObservation: create.asyncThunk(
      async (payload: { observationId: ObservationId; assetId: ObservationAssetId; files: File[] }, { dispatch }) => {
        const newFiles = (await alerting(() => postFilesToObservation(payload.observationId, payload.files), {
          dispatch,
        })) as ObservationBinary[];
        return {
          observationId: payload.observationId,
          assetId: payload.assetId,
          newFiles,
        };
      },
      {
        fulfilled: (state, { payload }) => {
          const obs = state.list.find((obs) => obs.id === payload.observationId);
          if (obs) {
            obs.binaries = [...(Array.isArray(obs.binaries) ? obs.binaries : []), ...payload.newFiles];
          }
        },
      },
    ),
    deleteFilesFromObservation: create.asyncThunk(
      async (
        payload: {
          observationId: ObservationId;
          assetId: ObservationAssetId;
          deletedFiles: ObservationBinary[];
        },
        { dispatch },
      ) => {
        const fileIds = payload.deletedFiles.map(({ id }) => id);
        await Promise.all(fileIds.map((id) => alerting(() => removeBinary(id), { dispatch })));
        return {
          observation: { id: payload.observationId },
          assetId: payload.assetId,
          deletedFileIds: fileIds,
        };
      },
      {
        fulfilled: (state, { payload }) => {
          const obs = state.list.find((o) => o.id === payload.observation.id);
          if (obs) {
            obs.binaries = (Array.isArray(obs.binaries) ? obs.binaries : []).filter(
              (binary) => !payload.deletedFileIds.includes(binary.id),
            );
            obs.updated = true;
          }
        },
      },
    ),
    deleteObservation: create.asyncThunk(
      async (payload: { observation: Observation; assetId: ObservationAssetId }, { dispatch }) => {
        await alerting(() => deleteApiObservation(payload.observation), { dispatch });
        success('Observations.delete_observations_snack', { params: 1, dispatch });
        return payload;
      },
      {
        fulfilled: (state, { payload }) => {
          state.list = state.list.filter((o) => o.id !== payload.observation.id);
        },
      },
    ),
    deleteObservations: create.asyncThunk(
      async (observationIds: ObservationId[], { dispatch }) => {
        await alerting(() => deleteApiObservations(observationIds), { dispatch });
        success('Observations.delete_observations_snack', { params: observationIds.length, dispatch });
        return observationIds;
      },
      {
        fulfilled: (state, { payload }) => {
          state.list = state.list.filter((o) => !payload.includes(o.id));
        },
      },
    ),
    archiveObservation: create.asyncThunk(
      async (payload: { observation: Observation; assetId: ObservationAssetId }, { dispatch }) => {
        const newObservation = (await alerting(() => archiveApiObservation(payload.observation.id), {
          dispatch,
        })) as Observation;
        dispatch(updatedObservation({ observation: newObservation, assetId: payload.assetId }));
        success('Observations.archive_observations_snack', { params: 1, dispatch });
      },
    ),
    archiveObservations: create.asyncThunk(
      async (observationIds: ObservationId[], { dispatch }) => {
        await alerting(() => archiveApiObservations(observationIds), { dispatch });
        success('Observations.archive_observations_snack', { params: observationIds.length, dispatch });
        return observationIds;
      },
      {
        fulfilled: (state, { payload }) => {
          state.list = state.list.map((o) =>
            payload.includes(o.id) ? { ...o, status: OBSERVATION_CONSTANTS.STATUS.ARCHIVED } : o,
          );
        },
      },
    ),
    restoreObservation: create.asyncThunk(
      async (payload: { observation: Observation; assetId: ObservationAssetId }, { dispatch }) => {
        const newObservation = (await alerting(() => restoreApiObservation(payload.observation.id), {
          dispatch,
        })) as Observation;
        dispatch(updatedObservation({ observation: newObservation, assetId: payload.assetId }));
        success('Observations.restore_observations_snack', { params: 1, dispatch });
      },
    ),
    restoreObservations: create.asyncThunk(
      async (observationIds: ObservationId[], { dispatch }) => {
        await alerting(() => restoreApiObservations(observationIds), { dispatch });
        success('Observations.restore_observations_snack', { params: observationIds.length, dispatch });
        return observationIds;
      },
      {
        fulfilled: (state, { payload }) => {
          state.list = state.list.map((o) =>
            payload.includes(o.id) ? { ...o, status: OBSERVATION_CONSTANTS.STATUS.AVAILABLE } : o,
          );
        },
      },
    ),

    addedObservation: create.reducer<{
      observation: Observation;
      assetId: ObservationAssetId;
    }>((state, { payload }) => {
      state.list = [payload.observation, ...state.list];
    }),
    updatedObservation: create.reducer<{
      observation: Observation;
      assetId: ObservationAssetId;
    }>((state, { payload }) => {
      state.list = state.list.map((o) => (o.id === payload.observation.id ? payload.observation : o));
    }),
    focusedObservation: create.reducer<ObservationId | null>((state, { payload }) => {
      state.focusedObservationId = payload || undefined;
    }),
  }),
  extraReducers: (builder) => {
    builder.addCase<string, { type: string; payload: ParcelId }>(
      Actions.PARCEL_DELETE_SUCCESS,
      (state, { payload }) => {
        state.list = state.list.filter((o) => o.parcel_id && o.parcel_id !== payload);
      },
    );

    builder.addCase<string, { type: string; payload: /* TODO: use EquipmentId */ number }>(
      Actions.EQUIPMENT_DELETE_SUCCESS,
      (state, { payload }) => {
        state.list = state.list.filter((o) => o.equipment_instance_id && o.equipment_instance_id !== payload);
      },
    );
  },
});

export const {
  fetchObservationCategories,
  createObservationCategory,
  updateObservationCategory,
  deleteObservationCategory,

  fetchObservations,
  createObservation,
  updateObservation,
  addFilesToObservation,
  deleteFilesFromObservation,
  deleteObservation,
  deleteObservations,
  archiveObservation,
  archiveObservations,
  restoreObservation,
  restoreObservations,

  addedObservation,
  updatedObservation,
  focusedObservation,
} = generalSlice.actions;
export default generalSlice.reducer;
